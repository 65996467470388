import React from "react";
import styled from "styled-components";
import { screenSizes } from "../../styles/theme";
import { useStaticQuery, graphql } from "gatsby";
import StyledImg from "./styled-gatsby-img";
import useWindowResize from "../../hooks/use-window-resize";

const StyledImgDesktop = styled(StyledImg)`
  margin-top: 0.5rem;
`;

const EvaImg = () => {
  const windowSize = useWindowResize();
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "eva.png" }) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const { fluid, fixed } = data.mobileImage.childImageSharp;
  const altText = "Eva Carlström";

  let image = <StyledImgDesktop fixed={fixed} alt={altText} />;
  if (windowSize.width < screenSizes.large) {
    image = <StyledImg fluid={fluid} alt={altText} />;
  }
  return image;
};

export default EvaImg;
