import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import theme from "../../styles/theme";
import Img from "gatsby-image";

interface Props {
  altText: string;
}

const CoverImage = ({ altText }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      default: file(relativePath: { eq: "amiral-square.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      xxSmall: file(relativePath: { eq: "amiral-xxSmall.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 438, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      xSmall: file(relativePath: { eq: "amiral-xSmall.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      small: file(relativePath: { eq: "amiral-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 650, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      medium: file(relativePath: { eq: "amiral-medium.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      large: file(relativePath: { eq: "amiral-large.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      xLarge: file(relativePath: { eq: "amiral-xLarge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      xxLarge: file(relativePath: { eq: "amiral-xxLarge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const sources = [
    data.default.childImageSharp.fluid,
    {
      ...data.xxLarge.childImageSharp.fluid,
      media: theme.screens.xxLargeMin,
    },
    {
      ...data.xLarge.childImageSharp.fluid,
      media: `${theme.screens.xLargeMin} and ${theme.screens.xxLargeMax}`,
    },
    {
      ...data.large.childImageSharp.fluid,
      media: `${theme.screens.largeMin} and ${theme.screens.xLargeMax}`,
    },
    {
      ...data.medium.childImageSharp.fluid,
      media: `${theme.screens.mediumMin} and ${theme.screens.largeMax}`,
    },
    {
      ...data.small.childImageSharp.fluid,
      media: `${theme.screens.smallMin} and ${theme.screens.mediumMax}`,
    },
    {
      ...data.xSmall.childImageSharp.fluid,
      media: `${theme.screens.xSmallMin} and ${theme.screens.smallMax}`,
    },
    {
      ...data.xxSmall.childImageSharp.fluid,
      media: `${theme.screens.xxSmallMin} and ${theme.screens.xSmallMax}`,
    },
  ];
  return <Img fluid={sources} alt={altText} />;
};

export default CoverImage;
