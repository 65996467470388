import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import StyledImg from "./styled-gatsby-img";

const BackpacksImg = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "backpacks.png" }) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledImg
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Ett flertal av fjällrävens klassiska ryggsäck kånken"
    />
  );
};

export default BackpacksImg;
