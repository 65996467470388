import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentWrapper from "../components/content-wrapper";
import Section from "../components/section";
import CoverImage from "../components/images/cover-image";
import Poem from "../components/poem";
import Paragraph from "../components/paragraph";
import List from "../components/list";
import Table from "../components/table";
import EvaImg from "../components/images/eva";
import BackpacksImg from "../components/images/backpacks";
import ColumnedChapter from "../components/columned-chapter";
import SectionHeading from "../components/section-heading";
import SectionSubHeading from "../components/section-sub-heading";

const IndexPage = () => (
  <Layout>
    <SEO title="Hem" />
    <Section id="top-section">
      <CoverImage altText="Amiralfjäril" />
      <ContentWrapper>
        <Poem
          author="Bo Setterlind"
          name="Till fjärilens minne"
          lines={[
            "Säg inte, att ingenting blir kvar ",
            "av den vackraste fjärilen livet gav.",
            "Säg inte, att vingarnas färg bleknar bort",
            "och försvinner i vinden som stoft, som stoft.",
            "Om fjärilens kropp måste gömmas i grav,",
            "är ändå den svindlande flykten kvar!",
          ]}
        />
      </ContentWrapper>
    </Section>
    <Section id="om-fonden" tinted displayArrow>
      <ContentWrapper>
        <SectionHeading>Om minnesfonden</SectionHeading>
        <SectionSubHeading>Bakgrund</SectionSubHeading>
        <Paragraph>
          Eva Carlström var en engagerad och professionell förskolelärare på
          flera förskolor i Vetlanda kommun. Under åren 2000 till 2012 arbetade
          hon med 6-åringar i förskoleklassen på Tomaslunden. Eva brann för sitt
          arbete med barnen och älskade att undervisa i och om naturen. Hon
          ville dela med sig av sina kunskaper om växter och småkryp och allra
          helst om fjärilar. Hon hälsar på oss ibland som en amiralfjäril,
          därför har den blivit en symbol för fonden.
        </Paragraph>
        <Paragraph>
          Eva gick bort i cancer en dag före sin 49-årsdag, den 2:a januari
          2016. De som inget visste kunde aldrig ana att hon var sjuk, hon var
          en kämpe och krigare utan motstycke! En fond till Eva Carlströms minne
          har, under 2016, startats av maken Fredrik Carlström tillsammans med
          barnen Oscar och Ida.
        </Paragraph>
        <Paragraph>
          Fondens syfte är att främja god kamratskap bland yngre elever samt
          uppmuntra till vistelse ute i naturen. Utgångspunkten har varit Evas
          egna ledord:
        </Paragraph>
        <List items={["Ta hand om varandra", "Hjälp varandra", "Kämpa"]} />
        <SectionSubHeading>Utdelning</SectionSubHeading>
        <Paragraph>
          Ett stipendium delas ut till en elev i förskoleklassen vid
          Tomaslundens skolenhet. Detta görs varje år i samband med läsårets
          avslutning. Förskoleklassens pedagogiska personal väljer, enligt
          fastställda kriterier, ut en lämplig elev. Stipendiet består av en
          Fjällräven Kånken original, samt ett diplom.
        </Paragraph>
        <BackpacksImg />
        <SectionSubHeading>Förvaltning</SectionSubHeading>
        <Paragraph>
          Fonden förvaltas av Vetlanda kommun och den är öppen för alla som vill
          stödja syftet. Inbetalning kan göras på Vetlanda kommuns{" "}
          <a href="#bidra">plusgirokonto</a>.
        </Paragraph>
      </ContentWrapper>
    </Section>
    <Section id="om-eva" displayArrow>
      <ContentWrapper>
        <SectionHeading>Om Eva</SectionHeading>
        <SectionSubHeading>Uppväxt</SectionSubHeading>
        <Paragraph>
          Eva växte upp på Östersand i Vetlanda, men föddes i Vimmerby 3 januari
          1967. Redan i unga år var hon aktiv inom flera idrotter och visade
          talang för både längdskidor, simning och fotboll. Efter ett år på
          skidgymnasiet i Sollefteå lade hon tävlingsskidorna på hyllan, men
          fortsatte som ambitiös motionär. Det blev bland annat 6 Vasalopp och 3
          Tjejvasalopp.
        </Paragraph>
        <SectionSubHeading>Yrkesliv</SectionSubHeading>
        <ColumnedChapter>
          <div>
            <Paragraph>
              Efter skidkarriären vaknade intresset för barn och pedagogik och
              hon utbildade sig i Växjö till förskolelärare. Det var framför
              allt lekens betydelse för barns utveckling som fångade hennes
              uppmärksamhet.
            </Paragraph>
            <Paragraph>
              Eva var en omtyckt förskolelärare och kollega som älskade sitt
              arbete. Det var viktigt för henne att barnen kände sig trygga i
              skogen och att de fick kunskaper för att ta ansvar för vår miljö.
              Speciellt visade hon barnen insekternas värld och vilken betydelse
              de har i naturen. Fjärilar var hennes egna favoriter och under
              flera år lät hon föda upp larver, som efter pupp-stadiet sedan
              blev vackra fjärilar som flög ut i klassrummet.
            </Paragraph>
            <Paragraph>
              Nya utmaningar väntade och efter ledarskapsutbildning fick hon
              jobb som förskolechef i Eksjö kommun.
            </Paragraph>
          </div>
          <EvaImg />
        </ColumnedChapter>
        <SectionSubHeading>Fritid</SectionSubHeading>
        <Paragraph>
          Evas fritid var fylld av lustfyllda aktiviteter. Natur och friluftsliv
          var, vid sidan av träning, hennes största intressen. Det blev många
          paddelturer längs Sveriges kust, mest skärgården runt Oskarshamn, där
          hon tillbringade mycket tid med familjen. Vandringar i fjällen och på
          naturstigar runt om i Sverige var också populära utflykter. Familj,
          vänner, kultur, musik och glada skratt var kärnan i hennes rika liv.
        </Paragraph>
      </ContentWrapper>
    </Section>
    <Section id="bidra" tinted displayArrow>
      <ContentWrapper>
        <SectionHeading>Bidra</SectionHeading>
        <SectionSubHeading>Från Sverige</SectionSubHeading>
        <Paragraph>
          Om du vill stödja fondens syfte kan du göra en inbetalning på Vetlanda
          kommunsplusgirokonto <strong>plusgirokonto (25200-7)</strong>. Kom
          ihåg att <strong>märka</strong> betalningen{" "}
          <strong>med ”Eva Carlström”</strong>.
        </Paragraph>
        <SectionSubHeading>Från utlandet</SectionSubHeading>
        <Paragraph>
          För att göra en insättning ifrån utlandet behöver du även ange{" "}
          <strong>IBAN</strong> och <strong>BIC-/SWIFT-adress</strong> för
          Vetlanda kommuns<strong> plusgirokonto (25200-7)</strong>.
        </Paragraph>
        <Table
          rows={[
            {
              column: "Kontonummer",
              data: "25200-7",
            },
            {
              column: "Meddelande",
              data: "Eva Carlström",
            },
            {
              column: "IBAN",
              data: "SE8395000099602600252007",
            },
            {
              column: "BIC/SWIFT",
              data: "NDEASESS",
            },
          ]}
        />
      </ContentWrapper>
    </Section>
    <Section id="kontakt">
      <ContentWrapper>
        <SectionHeading>Kontakt</SectionHeading>
        <Paragraph>
          Om du har frågor om minnesfonden, så kontakta oss gärna via e-post:{" "}
          <a href="mailto:evasminnesfond@gmail.com">evasminnesfond@gmail.com</a>
        </Paragraph>
      </ContentWrapper>
    </Section>
  </Layout>
);

export default IndexPage;
