import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode | ReactNode[];
}

const StyledColumnedChapter = styled.div`
  @media ${({ theme }) => theme.screens.largeMin} {
    display: flex;
    justify-content: space-between;
    * > {
      /* flex-shrink: 0; */
    }
    > :first-child {
      flex-basis: 60%;
      margin-right: 20px;
    }
  }
`;

const ImageDividedChapter = ({ children }: Props) => (
  <StyledColumnedChapter>{children}</StyledColumnedChapter>
);

export default ImageDividedChapter;
