import React from "react";
import styled from "styled-components";

interface Props {
  items: string[];
}

const StyledList = styled.ul`
  margin-bottom: 2em;
  margin-top: 2em;
  text-align: left;
  font-size: ${({ theme }) => theme.fonts.body.sizes.large};
`;

const StyledListItem = styled.li`
  margin-bottom: 1.1em;
  ::before {
    content: "- ";
  }
`;

const List = ({ items }: Props) => {
  const listItems = items.map(item => (
    <StyledListItem key={`list-item-${item}`}>{item}</StyledListItem>
  ));
  return <StyledList>{listItems}</StyledList>;
};

export default List;
