import React from "react";
import styled from "styled-components";

interface Row {
  column: string;
  data?: string | number | boolean | Date;
}

interface Props {
  rows: Row[];
}

const StyledTable = styled.table`
  margin: 0;
  padding: 0;
  border-width: 0;
  font-size: 120%;
`;

const cellPadding = "25px";

const StyledTh = styled.th`
  text-align: left;

  @media ${({ theme }) => theme.screens.largeMax} {
    display: block;
  }
  @media ${({ theme }) => theme.screens.largeMin} {
    padding-bottom: ${cellPadding};
  }
`;

const StyledTd = styled.td`
  text-align: left;
  padding-bottom: ${cellPadding};

  @media ${({ theme }) => theme.screens.largeMax} {
    display: block;
  }
  @media ${({ theme }) => theme.screens.largeMin} {
    padding-left: 2em !important;
  }
`;

const Table = ({ rows }: Props) => {
  const tableRows = rows.map((row, i) => (
    <tr key={`table-row-${i}`}>
      <StyledTh>{row.column}:</StyledTh>
      <StyledTd>{row.data}</StyledTd>
    </tr>
  ));
  return (
    <StyledTable>
      <tbody>{tableRows}</tbody>
    </StyledTable>
  );
};

export default Table;
