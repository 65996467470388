import React from "react";
import styled from "styled-components";
import Quotation from "../images/svgs/quotation_start.svg";

interface Props {
  name: string;
  author: string;
  lines: string[];
}

const StyledPoemText = styled.p`
  @media ${({ theme }) => theme.screens.xSmallMin} {
    font-size: ${({ theme }) => theme.fonts.body.sizes.large};
  }
  @media ${({ theme }) => theme.screens.xxSmallMax} {
    font-size: 98%;
  }
`;

const StyledPoemContent = styled(StyledPoemText)`
  max-width: 19em;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledQuotationMark = styled(Quotation)`
  text-align: left;
  display: block;
  @media ${({ theme }) => theme.screens.xSmallMin} {
    width: 4rem;
  }
  @media ${({ theme }) => theme.screens.xSmallMax} {
    width: 3rem;
  }
`;

const StyledPoemAuthor = styled(StyledPoemText)`
  ::before {
    content: "";
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 3rem;
    height: 0.4rem;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledPoemName = styled.strong`
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const StyledPoemLine = styled.span`
  display: block;
`;

const Poem = ({ name, author, lines }: Props) => {
  const poem = lines.map((line, i) => (
    <StyledPoemLine key={`poem-line-${i}`}>{line}</StyledPoemLine>
  ));
  return (
    <>
      <StyledPoemContent>
        <StyledQuotationMark />
        <em>{poem}</em>
      </StyledPoemContent>
      <StyledPoemAuthor>
        <StyledPoemName>{name}</StyledPoemName>
        <br />- {author} -
      </StyledPoemAuthor>
    </>
  );
};

export default Poem;
