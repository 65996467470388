import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode | ReactNode[];
}

const StyledSectionHeading = styled.h2`
  font: ${({ theme }) => theme.fonts.headings.bold};
  margin-bottom: 0.7rem;
  font-size: 120%;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: left;
`;

const SectionSubHeading = ({ children }: Props) => (
  <StyledSectionHeading>{children}</StyledSectionHeading>
);

export default SectionSubHeading;
